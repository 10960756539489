import {
  components,
} from './openapi.ts';

export enum ENotificationChannelType {
  EMAIL = 'email',
  IN_APP = 'in_app',
  SMS = 'sms',
}

export const notificationChannelTypeMap: Record<ENotificationChannelType, components['schemas']['NotificationChannelType']> = {
  [ENotificationChannelType.EMAIL]: 'email',
  [ENotificationChannelType.IN_APP]: 'in_app',
  [ENotificationChannelType.SMS]: 'sms',
};
