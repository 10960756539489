export default {
  displayName: {
    label: 'Name',
    tooltip: 'Notification channel display name',
  },
  description: {
    label: 'Description',
    tooltip: 'Description of notification channel',
  },
  senderEmail: {
    label: 'Sender E-mail',
    tooltip: 'The sender email to use',
  },
  serverHost: {
    label: 'E-mail server',
    tooltip: 'SMTP server host',
  },
  serverPort: {
    label: 'SMTP-Port',
    tooltip: 'SMTP server port',
  },
  useSsl: {
    label: 'UseSSL',
    tooltip: 'Whether to use SSL',
  },
  credential: {
    label: 'Credential',
    tooltip: 'Set or create Credential.',
    setCredential: 'Set credentials',
    updatingcredential: 'Update credentials',
    credentialUpdated: 'Credential will be updated once saved.',
  },
  notificationChannel: 'Notification channels',
  email: 'E-mail',
};
