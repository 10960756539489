export default {
  notAvailable: 'N/A',
  signal: 'Signal',
  xCursor: 'X Cursor',
  x1: 'X1',
  x2: 'X2',
  x2_x1: 'X2-X1',
  yCursor: 'Y Cursor',
  y1: 'Y1',
  y2: 'Y2',
  y2_y1: 'Y2-Y1',
  min: 'Min',
  max: 'Max',
  average: 'Average',
  stdandardDeviation: 'Std',
};
