export default {
  displayName: {
    label: 'Name',
    tooltip: 'Anzeigename des Benachrichtigungskanals',
  },
  description: {
    label: 'Beschreibung',
    tooltip: 'Beschreibung des Benachrichtigungskanals',
  },
  senderEmail: {
    label: 'Absender-E-Mail',
    tooltip: 'Die Absender-E-Mail, die verwendet werden soll',
  },
  serverHost: {
    label: 'E-Mail-Server',
    tooltip: 'SMTP-Serverhost',
  },
  serverPort: {
    label: 'SMTP-Port',
    tooltip: 'SMTP-Serverport',
  },
  useSsl: {
    label: 'Anmeldeinformation',
    tooltip: 'Ob SSL verwendet werden soll',
  },
  credential: {
    label: 'Anmeldeinformation',
    tooltip: 'Anmeldeinformation setzen oder erstellen.',
    buttonSetCredential: 'Anmeldeinformationen festlegen',
    buttonUpdatingcredentials: 'Anmeldeinformationen aktualisieren',
    credentialUpdated: 'Die Anmeldeinformationen werden aktualisiert, sobald Sie auf Speichern drücken.',
  },
  notificationChannel: 'Benachrichtigungskanäle',
  email: 'E-mail',
};
